  .dashboardCenter {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 95% !important;
    height: 100% !important;
    background: #f1f4f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    text-align: center;
    position: relative;
  }

  .divCenterDash{
    height: 100vh;
    width: 100% !important;
    text-align: center !important;
  }
