@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Inter var', Roboto, Helvetica Neue, Arial, sans-serif;
  color: dimgray;

  /* The image used */
  background-image: url('../public/assets/support_system_bg_mafi_technologies.jpg');

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
}

html,
body {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

html,
body {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  min-height: 100%;
  width: 100%;
  flex: 1 1 auto;
}

#root {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 100%;
}