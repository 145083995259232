.navigationBlock {
    /* width: 100%;
    max-width: 300px;
    min-width: 200px !important;
    height: 98vh; */
    height: 170% !important;
    background-color: #1e293b;
    padding-top: 8px;
    color: #fff;
}

/* div horizontal */
.aParent div {
    float: left !important;
    clear: none !important;
}

.logoSaltex {
    height: 40px;
    margin-top: 20px;
}

.logoMafi {
    height: 40px;
    margin-top: 20px;
    padding-right: 20px;
}

.poweredByLogo {
    height: 40px;
    padding-right: 20px;
    margin-left: 20px;
}

.avatarUser {
    width: 100px !important;
    height: 100px !important;
    text-align: center !important;
    margin-top: 40px;
}

.navTitle {
    font-size: 14px;
    padding-top: 10px;
    color: #818cf8;
}

.navSubTitle {
    font-size: 12px !important;
    margin-top: -15px !important;
    color: rgb(148, 163, 184);
}

.dividerStyle {
    background-color: #818cf8 !important;
    margin-top: 20px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    width: 80% !important;
    opacity: 60% !important;
}