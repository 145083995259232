  .dashboardCenter {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 95% !important;
    height: 100% !important;
    background: #f1f4f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    text-align: center;
    position: relative;
  }

  /* .divCenterDash {
    height: 100%;
    width: 100% !important;
    text-align: center !important;
  } */

  .logo {
    display: block;
    margin-left: auto;
    margin-right: -100px;
    min-width: 80%;
    max-width: 250px;
    padding-top: 20px;
  }

  .ticketsByDay {
    width: 99%;
    text-align: center;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

  }

  .dashHeader {
    padding-top: 10px;
    width: 100%;
  }

  .ticketsByDayChart {
    background-color: #1e293b !important;
    border-radius: 10px !important;
    padding: 10px !important;
  }

  .ticketsByDayChartTitle {
    text-align: left;
    position: inherit;
    font-size: 25px !important;
    color: white;
    font-weight: bold !important;
    position: absolute;
    padding-left: 70px;
    padding-top: 10px;
  }

  .ticketsByDayChartChip {
    text-align: left;
    position: inherit;
    font-size: 15px !important;
    color: white;
    position: absolute;
    padding-left: 70px;
    padding-top: 50px;
  }

  /* apply for all square charts */
  .totalTicketsChart {
    margin: 15px 20px 0px 30px;
    height: 170px;

    border-radius: 10px;
  }

  .totalTicketsTitle {
    padding-top: 5px;
    padding-left: 10px;
    font-weight: bold !important;
    font-size: 15px !important;
    text-align: left;
    color: dimgray;
  }

  .totalTicketsAmountDiv {
    text-align: left !important;
    color: rgb(0, 0, 0);
    font-size: 30px !important;
    padding-left: 20px;
    font-weight: bold !important;
    position: absolute;
    z-index: 90;
  }



  /** paper */
  .paperDashBox {
    display: flex;
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px 0 var(--tw-shadow-color) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
    border-radius: 1.6rem !important;
    overflow: hidden !important;
    flex-direction: column !important;
    flex: 1 1 auto !important;
    width: 100% !important;
    margin-left: -20px;
  }

  .divTopBox {
    padding-top: 1.2rem !important;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
    justify-content: space-between !important;
    align-items: center !important;
    display: flex !important;
  }

  .titleTypoBox {
    letter-spacing: -0.025em !important;
    line-height: 1rem !important;
    font-weight: 500 !important;
    font-size: 1.2rem !important;
    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .divCenterBox {
    text-align: center !important;
    margin-top: 0.8rem !important;
  }

  .TextCountBoxRed {
    --tw-text-opacity: 1 !important;
    color: rgb(244 67 54 / var(--tw-text-opacity)) !important;
    letter-spacing: -0.025em !important;
    line-height: 1 !important;
    font-weight: 600 !important;
    font-size: 4rem !important;
  }

  .subTitleBoxRed {
    --tw-text-opacity: 1 !important;
    color: rgb(229 57 53 / var(--tw-text-opacity)) !important;
    font-weight: 500 !important;
    font-size: 1.3rem !important;
  }

  .TextCountBoxBlue {
    --tw-text-opacity: 1 !important;
    color: rgb(33 150 243 / var(--tw-text-opacity)) !important;
    letter-spacing: -0.025em !important;
    line-height: 1 !important;
    font-weight: 600 !important;
    font-size: 4rem !important;
  }

  .subTitleBoxBlue {
    --tw-text-opacity: 1 !important;
    color: rgb(33 150 243 / var(--tw-text-opacity)) !important;
    font-weight: 500 !important;
    font-size: 1.3rem !important;
  }

  .TextCountBoxYellow {
    --tw-text-opacity: 1 !important;
    color: rgb(255 193 7 / var(--tw-text-opacity)) !important;
    letter-spacing: -0.025em !important;
    line-height: 1 !important;
    font-weight: 600 !important;
    font-size: 4rem !important;
  }

  .subTitleBoxYellow {
    --tw-text-opacity: 1 !important;
    color: rgb(255 193 7 / var(--tw-text-opacity)) !important;
    font-weight: 500 !important;
    font-size: 1.3rem !important;
  }

  .TextCountBoxGreen {
    --tw-text-opacity: 1 !important;
    color: rgb(76 175 80 / var(--tw-text-opacity)) !important;
    letter-spacing: -0.025em !important;
    line-height: 1 !important;
    font-weight: 600 !important;
    font-size: 4rem !important;
  }

  .subTitleBoxGreen {
    --tw-text-opacity: 1 !important;
    color: rgb(76 175 80 / var(--tw-text-opacity)) !important;
    font-weight: 500 !important;
    font-size: 1.3rem !important;
  }

  .totalsButtonBox {
    justify-content: center !important;
    align-items: baseline !important;
    width: 100% !important;
    display: flex !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .truncateBox {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .px8Box {
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
  }

  b,
  strong {
    font-weight: bolder;
  }

  /** second line */

  .dashSecondLine {
    width: 94%;
    background-color: white;
    border-radius: 20px;
    padding: 0px 20px 0px 20px;
    margin-top: 20px;
    margin-left: 20px;
    float: left;
    clear: none;
  }

  .dashSecondLine div {
    float: left;
    clear: none;
  }

  .dashChart {
    width: 45%;
    display: flex;
  }

  .dashSecondLineBoxes {
    width: 55%;
  }