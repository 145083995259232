.signInPageCenter {
  margin: auto;
  width: min-content;
  padding: 10px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  text-align: center;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  padding: 20px;
}

.signInContainer {
  /* max-width: 80%; */
}

.signInForm {
  margin: 30px;
}

.signInFooter {
  max-width: 100%;
  text-align: center;
  margin-left: -35px !important;
  padding: 10px;
}

.dashboardCenter {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 90% !important;
  padding: 10px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 10px;
  text-align: center;
  position: relative;
}