.analiticsCenter {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 95% !important;
  height: 230% !important;
  background: #f1f4f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  text-align: center;
  position: relative;
}

.divCenterDash{
  height: 100vh;
  width: 100% !important;
  text-align: center !important;
}

.ticketsByDay{
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;    

}

.ticketsByDayChart{
  background-color: #1e293b !important;
  border-radius: 10px !important;
  padding: 10px !important;
  width: 100% !important;
}

.ticketsByDayChartTitle {
  text-align: left;
  position: inherit;
  font-size: 25px !important;
  color: white;
  font-weight: bold !important;
  position: absolute;
  padding-left: 70px;
  padding-top: 10px;
}

.ticketsByDayChartChip {
  text-align: left;
  position: inherit;
  font-size: 15px !important;
  color: white;
  position: absolute;
  padding-left: 70px;
  padding-top: 50px;
}

 /* apply for all square charts */
 .totalTicketsChart{
  margin: 15px 20px 0px 30px;
  height: 170px;

  border-radius: 10px;
}

.totalTicketsTitle{
  padding-top: 5px;
  padding-left: 10px;
  font-weight: bold !important;
  font-size: 15px !important;
  text-align: left;
  color: dimgray;
}

.totalTicketsAmountDiv{
  text-align: left !important;
  color: rgb(0, 0, 0);
  font-size: 30px !important;
  padding-left: 20px;
  font-weight: bold !important;
  position: absolute;
  z-index: 90;
}