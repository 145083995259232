/* company colors */ 
.saltexColor{
    color: #0e4072 !important;
}

.bgSaltexColor{
    background-color: #1e293b !important; 
}

.bgPage{
    background-color: #f1f4f9 !important;
}

/*height*/
.h50{
    height: 50px !important;
}

.h200{
    height: 200px !important;
}
.h400{
    height: 400px !important;
}
.h500{
    height: 500px !important;
}

.h-full{
    height: 100% !important;
}

/*width*/
.w50{
    width: 50px !important;
}

.w100{
    width: 100px !important;
}

.w200{
    width: 200px !important;
}

.w-full{
    width: 100% !important;
}

/* margin */

/*8px*/
.mt8{
    margin-top: 8px !important;
}

.mb8{
    margin-bottom: 8px !important;
}

.ml8{
    margin-left: 8px !important;
}

.mr8{
    margin-right: 8px !important;
}

/*10px*/
.mt10{
    margin-top: 10px !important;
}

.mb10{
    margin-bottom: 10px !important;
}

.ml10{
    margin-left: 10px !important;
}

.mr10{
    margin-right: 10px !important;
}

/*20px*/
.mt20{
    margin-top: 20px !important;
}

.mb20{
    margin-bottom: 20px !important;
}

.ml20{
    margin-left: 20px !important;
}

.mr20{
    margin-right: 20px !important;
}

/* padding */
/*10px*/
.pt5{
    padding-top: 5px !important;
}

.pb5{
    padding-bottom: 5px !important;
}

.pl5{
    padding-left: 5px !important;
}

.pr5{
    padding-right: 5px !important;
}

/*10px*/
.pt10{
    padding-top: 10px !important;
}

.pb10{
    padding-bottom: 10px !important;
}

.pl10{
    padding-left: 10px !important;
}

.pr10{
    padding-right: 10px !important;
}

/*15px*/
.pt15{
    padding-top: 15px !important;
}

.pb15{
    padding-bottom: 15px !important;
}

.pl15{
    padding-left: 15px !important;
}

.pr15{
    padding-right: 15px !important;
}

/*20px*/
.pt20{
    padding-top: 20px !important;
}

.pb20{
    padding-bottom: 20px !important;
}

.pl20{
    padding-left: 20px !important;
}

.pr20{
    padding-right: 20px !important;
}

/*30px*/
.pt30{
    padding-top: 30px !important;
}

.pb30{
    padding-bottom: 30px !important;
}

.pl30{
    padding-left: 30px !important;
}

.pr30{
    padding-right: 30px !important;
}

/* text */
.font-size-10{
    font-size: 10px !important;
}

.font-size-12{
    font-size: 12px !important;
}

.font-size-20{
    font-size: 20px !important;
}

.font-size-25{
    font-size: 25px !important;
}

.font-bold{
    font-weight: bold !important;
}

/*Align*/
.alignRight{
    text-align: right !important;
}
.alignLeft{
    text-align: left !important;
}

.alignCenter{
    text-align: center !important;
}

/* font Colors*/
.font-white{
    color: white !important;
}

/* min-width % */
.mw10 {
    min-width: 10%;
}
.mw20 {
    min-width: 20%;
}
.mw30 {
    min-width: 30%;
}
.mw40 {
    min-width: 40%;
}
.mw50 {
    min-width: 50%;
}
.mw60 {
    min-width: 60%;
}